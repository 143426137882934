import { useEffect, useState } from "react";
import henceforthApi from "../Utiles/henceforthApi";

const Terms = () => {
    const [termsData, setTermsData] = useState({
        data:
        {
            _id: "",
            created_at: "",
            description: "",
            image: "",
            type: ""
        }
    })
    const getTermsAndConditions = async () => {
        try {
            let res = await henceforthApi.Common.content("TERMS_AND_CONDITIONS")
            console.log(res);
            setTermsData(res)
        } catch (error) {
            }
    }
    useEffect(() => {
        getTermsAndConditions()
    }, [])
    return (
        <>
        <div className="terms p-4">
            <h1>Terms And Conditions</h1>
            <div
                className="word-break-policies-detail text-dark"
                dangerouslySetInnerHTML={{ __html: termsData?.data?.description }} >
            </div>
        </div>
        </>
    )
}
export default Terms